<template>
  <div id="app">
    <!-- <div class="tel">
      <img src="./assets/kefu@2x.png" alt="" />
      <div class="inside">
        <div class="inBox">
          <div @click="dialogFormVisible = true">在线留言</div>
          <div>
            <el-tooltip class="item" effect="light" placement="left">
              <div slot="content">
                <img src="./assets/code.png" style="width: 150px" alt="" />
              </div>
              <span>微信二维码</span>
            </el-tooltip>
          </div>
          <div>
            <a
              target="_blank"
              href="http://wpa.qq.com/msgrd?v=3&uin=416376471&site=qq&menu=yes"
              >QQ客服</a
            >
          </div>
        </div>
      </div>
    </div> -->
    <router-view />
    <!-- <el-dialog
      :visible.sync="dialogFormVisible"
      :show-close="false"
      :width="!btnV ? '744px' : '647px'"
    >
      <div slot="title">
        <img @click="close" src="./assets/guanbi@2x.png" alt="" />
      </div>
      <div class="pro" v-if="!btnV">
        <h3>在线留言</h3>
        <div style="margin-bottom: 25px">
          <div class="tips">您的姓名</div>
          <el-input v-model="form.name"></el-input>
        </div>
        <div>
          <div class="tips">您的手机号</div>
          <el-input v-model="form.phone"></el-input>
        </div>
        <div style="margin-top: 28px" class="leaving">
          <div class="tips">留言板</div>
          <el-input
            v-model="form.content"
            type="textarea"
            resize="none"
          ></el-input>
        </div>
        <el-button @click="btn">提交</el-button>
      </div>
      <div class="proEnter" v-else>
        <img src="./assets/chenggong@2x.png" alt="" />
        <h3>提交成功</h3>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        phone: "",
        content: "",
      },
      btnV: false,
    };
  },
  methods: {
    close() {
      this.dialogFormVisible = false;
      Object.assign(this.$data.form, this.$options.data().form);
      setTimeout(() => {
        this.btnV = false;
      }, 300);
    },
    btn() {
      const that = this;
      let text = /^[1][3,4,5,7,8][0-9]{9}$/;
      for (let i in that.form) {
        console.log(that.form[i]);
        if (that.form[i] === "") {
          return that.$message.error("请填写完整");
        }
      }
      if (!text.test(that.form.phone)) {
        return that.$message.error("手机号有误");
      }
      that.$http
        .postApi("/board_add", that.form)
        .then((res) => {
          if (res.data.code === 200) {
            this.btnV = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // 友盟统计添加
    const script = document.createElement("script");
    script.innerHTML = document.write(
      unescape(
        "%3Cspan id='cnzz_stat_icon_1280357463'%3E%3C/span%3E%3Cscript src='https://v1.cnzz.com/stat.php%3Fid%3D1280357463%26show%3Dpic1' type='text/javascript'%3E%3C/script%3E"
      )
    ); //该路径为点击统计代码后，页面中第一个 文字统计 script代码
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
  watch: {
    $route: function (to, from) {
      if (to.name.indexOf("_p") > 0 && !this.$pc) {
        this.$router.replace(to.name.split("_p")[0]);
        //电脑转手机端
        //域名为index_p去掉_p变成手机端index路由
        if (to.name == "index_p") {
          let str = to.name.split("_p")[0];
          let str2 = "/" + str;
          this.$router.push(str2);
        }
      } else if (to.name.indexOf("_p") < 0 && this.$pc) {
        //手机端转电脑
        //在路由尾巴添加_p变成电脑端
        this.$router.replace(to.name + "_p");
      }
    },
    $route() {
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.tel {
  position: fixed;
  right: 0;
  top: 22%;
  z-index: 1;
  min-width: 93px;
  min-height: 158px;
  img {
    width: 93px;
    height: 158px;
    position: absolute;
    right: 0;
    transition: right 0.8s ease;
  }
  .inside {
    // background-image: url("./assets/组 1@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 155px;
    height: 256px;
    position: absolute;
    right: -155px;
    transition: right 0.8s ease;
    .inBox {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      > div {
        width: 83px;
        height: 29px;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        color: #666666;
        line-height: 29px;
        cursor: pointer;
        &:hover {
          background: #e60412;
          color: #ffffff;
          a {
            color: white;
          }
        }
        a {
          color: #666666;
        }
      }
      div + div {
        margin-top: 12px;
      }
    }
  }
  &:hover {
    img {
      right: -93px;
    }
    .inside {
      right: 0;
    }
  }
}
.el-dialog__header {
  text-align: right;
  padding: 30px 32px 10px;
}
.el-dialog .el-dialog__body {
  padding: 0 71px 87px 93px;
}
.pro {
  text-align: center;
  h3 {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 77px;
  }
  > div {
    display: flex;
    .tips {
      width: 146px;
      height: 51px;
      background: #e60412;
      font-size: 19px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 51px;
      margin-right: 8px;
    }
    .el-input__inner,
    .el-textarea__inner {
      border-radius: 0;
      border: 2px solid #dcdcdc;
    }
    .el-input__inner {
      height: 51px;
      line-height: 51px;
    }
  }
  .leaving {
    .tips {
      height: 115px;
      line-height: 115px;
    }
    .el-textarea__inner {
      height: 115px;
    }
  }
  .el-button {
    width: 256px;
    height: 58px;
    background: #e60412 !important;
    border-radius: 0;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 500;
    color: #fffefe !important;
  }
}
.proEnter {
  padding-top: 57px;
  text-align: center;
  h3 {
    margin-top: 65px;
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 2px;
  }
}
</style>
