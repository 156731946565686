import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Mobileterminal/Home.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    //跳转到index_p，并且默认是首页
    {
      path: '/',
      redirect: 'index_p',
    },
    {
      path: '/index_p',
      name: 'index_p',
      component: resolve => require(['../views/Home.vue'], resolve),
      meta: {
        active: "1"
      }
    },
    {
      path: '/index',
      name: 'index',
      component: resolve => require(['../views/Mobileterminal/Home.vue'], resolve),
    },
    {
      path: '/Introduction',
      name: 'Introduction',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ '../views/Introduction.vue')
    },
    {
      path: '/newsCenter',
      name: 'newsCenter',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ '../views/news/index.vue'),
      children: [{
        path: '/newsDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/news/detail.vue'),
      }]
    },
    {
      path: '/Aboutus_s',
      name: 'Aboutus_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/Aboutus.vue'),
    },
    {
      path: '/About_us_s',
      name: 'About_us_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/About_us.vue'),
    },
    {
      path: '/contactus_s',
      name: 'contactus_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/contactus.vue'),
    },
    {
      path: '/DetailPage_s',
      name: 'DetailPage_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/DetailPage.vue'),
    },
    {
      path: '/Exhibition_s',
      name: 'Exhibition_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/Exhibition.vue'),
    },
    {
      path: '/exhibitionsDetail_s',
      name: 'exhibitionsDetail_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/exhibitionsDetail.vue'),
    },
    {
      path: '/ExhibitionDetail_s',
      name: 'ExhibitionDetail_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/ExhibitionDetail.vue'),
    },
    {
      path: '/newsDetails_s',
      name: 'newsDetails_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/newsDetails.vue'),
    },
    {
      path: '/exhibitionHallDetail_s',
      name: 'exhibitionHallDetail_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/exhibitionHallDetail.vue'),
    },
    {
      path: '/onlineExhibitionHall_s',
      name: 'onlineExhibitionHall_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/onlineExhibitionHall.vue'),
    },
    {
      path: '/exhibitionHall_s',
      name: 'exhibitionHall_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/exhibitionHall.vue'),
    },
    {
      path: '/exhibitions_s',
      name: 'exhibitions_s',
      component: () => import( /* webpackChunkName: "about" */ '../views/Mobileterminal/exhibitions.vue'),
    },
    {
      path: '/secondaryPage',
      name: 'secondaryPage',
      component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage'),
      children: [{
        path: '/galleryPavilion',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/galleryPavilion.vue'),
      }, {
        path: '/galleryPavilionDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/galleryPavilionDetail.vue'),
      }, {
        path: '/exhibition',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/exhibition.vue'),
      }, {
        path: '/exhibitionDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/exhibitionDetail.vue'),
      }, {
        path: '/ExhibitionsDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/ExhibitionsDetail.vue'),
      }, {
        path: '/Cultural',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/Cultural.vue'),
      }, {
        path: '/CulturalDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/CulturalDetail.vue'),
      }, {
        path: '/modularization',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/modularization.vue'),
      }, {
        path: '/modulDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/modulDetail.vue'),
      }, {
        path: '/Aboutus',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/Aboutus.vue'),
      }, {
        path: '/Exhibitions',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/Exhibitions.vue'),
      }, {
        path: '/About',
        component: () => import( /* webpackChunkName: "about" */ '../views/secondaryPage/About.vue'),
      }]
    }
  ]
})

export default router