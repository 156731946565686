import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'mv-full-page/lib-dist/mv-full-page.css'
import MvFullPage from 'mv-full-page'
import 'animate.css/animate.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import postApi from "./utils/api";


// import cryptoJs from "crypto-js";
const Bus = new Vue()
Vue.use(MvFullPage)
Vue.use(ElementUI)
Vue.use(Vant);


Vue.prototype.$bus = Bus;
Vue.prototype.$http = postApi;
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._czc) {
      window._czc.push(['_trackEvent', '点击', '该事件执行的内容']);
      console.log(window._czc, to.fullPath)
    }
  }
  next()
})

// 判断
// vue原型挂载 - 是否PC端
// if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
//   Vue.prototype.$pc = false
//   //hostConfig.vconsole && new VConsole()
// } else {
//   Vue.prototype.$pc = true
//   let winWidth = document.documentElement.offsetWidth ||
//     document.body.offsetWidth
//   winWidth = winWidth < 1366 ? 1366 : winWidth
//   let oHtml = document.getElementsByTagName('html')[0]
//   oHtml.style.fontSize = 100 * winWidth / 1920 + 'px'
//   window.addEventListener('resize', function () {
//     let winWidth = document.documentElement.offsetWidth || document.body.offsetWidth
//     winWidth = winWidth < 1366 ? 1366 : winWidth
//     let oHtml = document.getElementsByTagName('html')[0]
//     oHtml.style.fontSize = 100 * winWidth / 1920 + 'px'
//   })
// }
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
